
.isRotated svg{
    transform: rotate(-90deg);
  }

  .react-bootstrap-table .table{
    margin-bottom: 1.25rem;
    border-bottom: 1px solid #e9ecef;
  }

  .div-horizontal {
    overflow: auto;
    white-space: nowrap;
  }
  .table {
    overflow: auto;
    table-layout: auto !important;
  } 
  .row{
    margin-left: 0px;
    margin-bottom: 6px;
  }
#pageDropDown{
  color: #000;
  background-color: #f6f9fc;
  padding: 2px 5px;
  border-color: none;
  box-shadow: none;
}